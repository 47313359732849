import "./Browse4.css";
import { Rate } from "antd";
import { Link } from "react-router-dom";
import Member1 from "../Member1/Member1";
import { SiFacebook } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import { data } from "../../Data/AllData";
import ContactHero from "../ContactHero/ContactHero";
export default function Browse4() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Browse4-page-main-section"
    >
      {" "}
      <ContactHero />
      <div className=" main-Browse4-page container">
        <div className="Browse4-page-para2 text-center mt-5">
          {/* <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            Leadership Team
          </h1> */}
        </div>
        <h2
          className="text-left mt-5 mx-5"
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
        >
          JCOM MARTHANDAM 1.0
        </h2>
        <div className="row g-0 text-center main-Browse4-page ">
          {data.map((e) => (
            <Link
              to={`/BrowserSidePage/${e.id}`}
              key={e + Math.random()}
              className="col-lg-4  col-md-6 "
            >
              <div className="mx-2 p-5  in-box my-3 text-center">
                <img className="img-fluid  col-12" src={`${e.image}`} />
                <div className=" Browse4-page-person mb-3 fw-bold fs-5 pt-4">
                  {e.person}
                  <div className=" Browse4-page-name mb-1 my-3">{e.name}</div>
                </div>
                <div className=" Browse4-page-type mb-3">{e.type}</div>
                <div className=" Browse4-page-icon ">{e.icon}</div>
              </div>
            </Link>
          ))}
        </div>
        <Member1 />
      </div>
    </div>
  );
}
