import React, { Component, useState } from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import img3 from "../images/img3.png";
import img4 from "../images/img4.png";
import img5 from "../images/img5.png";
import "./Career.css";

const Career = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [position, setPosition] = useState("");
  const handleOk = () => {
    setModalVisible(false);
  };
  const data = [
    {
      image: img3,
      name: "Growth Fall",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      image: img4,

      name: "Solution Board",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
    {
      image: img5,

      name: "Schooling",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
  ];
  return (
    <div>
      <main className="side-Career-page" id="nature">
        <section
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="Career-page container"
        >
          <div className=" Career-page-heading text-center">
            <h1>WHAT IS JCOM ?</h1>
          </div>
          <div className=" Career-page-para text-center">
            <p className="">
              WE PROVIDE MOST EXCLUSIVE BUSINESS OPPORTUNITIES FOR JCI MEMBERS
            </p>
          </div>
          <div className="d-lg-flex"></div>
          <div className="row g-3  content-pack">
            {data.map((e) => (
              <div key={e + Math.random()} className="col-lg-4 col-md-6 ">
                <div className="mx-2 p-5 in-carrer-box text-center ">
                  <div className=" ">
                    <div className="">
                      <div className="my-4 ">
                        <img
                          className="img-fluid mt-1 text-center "
                          src={`${e.image}`}
                        />
                      </div>
                      <div className=" Career-name text-center ">{e.name}</div>
                      {/* <div className=" Career-content text-center">
                        {e.content}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};
export default Career;
