import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import video from "../videos/video.mp4";
import "./Hero.css";
import CountUp from "react-countup";
import { AiOutlineMinus } from "react-icons/ai";
const Hero = () => (
  <div>
    <main className="main-hero-page" id="nature">
      <div className="hero-video-box order-md-1 order-0">
        <video className="" autoplay="autoplay" loop="true" muted>
          <source src={video} type="video/mp4"></source>
        </video>
      </div>
      <section className="hero-page container">
        <div>
          <div className="hero-page-content text-left">
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              Let’s Connect for <br />
              business
            </h1>
            {/* <p
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              className="hero-page-para1"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui
              faucibus in ornare quam viverra orci sagittis eu volutpat.
              Pharetra sit amet aliquam id diam maecenas ultricies.Dui faucibus
              in ornare quam viverra orci sagittis eu volutpat.
            </p> */}
          </div>

          <div className="text-left pt-3">
            <span className="hero-btn text-left">
              <Button type="primary" key="console">
                CONTACT US
              </Button>
            </span>
          </div>

          {/* <div className="hero-page-para text-left">
            <AiOutlineMinus />
            <span>
              <p
                className="hero-page-para2 mx-3"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
              >
                Lorem ipsum dolor sit amet, consectetur <br />
                adipiscing elit, sed do.
              </p>
            </span>
            <br></br>
          </div> */}
        </div>
      </section>
    </main>
  </div>
);

export default Hero;
