// import img8 from "../Components/images/img8.png";
// import img9 from "../Components/images/img9.png";
// import img10 from "../Components/images/img10.png";
import img33 from "../Components/images/img33.jpeg";
import img32 from "../Components/images/img32.jpg";
export const data = [
  {
    id: 1,
    image: img33,
    person: "J. Naveen John",
    type: "Zone Chairman",
    email: "naveen@mountdeep.com",
    phonenumber: "+91 8056270629",
    companyname:
      "Obo Technology Pvt Ltd, 2nd floor John Plaza, Near SETC Bus Depot, Pammam, Marthandam, 629165.",
    website: "www.obotechnology.com",
    location: "Marthandam",
    products: "Kumarikalvi, Obo Cloud Store, Obo Event, Obo Appointment",
    services:
      "Obo Robotics, Architecting and Software Development, Web Page development, Mobile and Web App Development, Micro services, Cloud Support and Maintenance and Customer support.",
  },
  {
    id: 2,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 3,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 4,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 5,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
];
export const data1 = [
  {
    id: 6,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 7,
    image: img32,
    person: "R C Rakhi jain",
    type: "Past Nation President",
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
    id: 8,
  },
  {
    id: 9,

    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 10,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
];
export const data2 = [
  {
    id: 11,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 12,
    image: img32,
    person: "R C Rakhi jain",
    type: "Past Nation President",
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
    id: 13,
  },
  {
    id: 14,

    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 15,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
];
export const data3 = [
  {
    id: 16,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 17,
    image: img32,
    person: "R C Rakhi jain",
    type: "Past Nation President",
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
    id: 18,
  },
  {
    id: 19,

    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 20,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
];
export const data4 = [
  {
    id: 21,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 22,
    image: img32,
    person: "R C Rakhi jain",
    type: "Past Nation President",
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
    id: 23,
  },
  {
    id: 24,

    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 25,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
];
export const data5 = [
  {
    id: 26,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 27,
    image: img32,
    person: "R C Rakhi jain",
    type: "Past Nation President",
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
    id: 28,
  },
  {
    id: 29,

    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 30,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
];
export const data6 = [
  {
    id: 31,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 32,
    image: img32,
    person: "R C Rakhi jain",
    type: "Past Nation President",
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
    id: 33,
  },
  {
    id: 34,

    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
  {
    id: 35,
    image: img32,
    person: "Person Name",
    type: "Position",
    email: "Email Id",
    phonenumber: "Phonenumber",
    companyname: "Companyname",
    website: "Website",
    location: "Location",
    products: "Products",
    services: " Services",
  },
];
