import "./Browse7.css";
import { Rate } from "antd";
import { Link } from "react-router-dom";
import img8 from "../images/img8.png";
import img9 from "../images/img9.png";
import img10 from "../images/img10.png";
import img11 from "../images/img11.png";
import img12 from "../images/img12.png";
import img13 from "../images/img13.png";
import { SiFacebook } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import { data3 } from "../../Data/AllData";
import ContactHero from "../ContactHero/ContactHero";
import Member1 from "../Member1/Member1";
export default function Browse7() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Browse7-page-main-section"
    >
      {" "}
      <ContactHero />
      <div className=" main-Browse7-page container">
        <div className="Browse7-page-para2 text-center mt-5">
          {/* <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            Leadership Team
          </h1> */}
        </div>
        <h2
          className="text-left mt-5 mx-5"
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
        >
          JCOM - Thirunelveli 1.0 (Members)
        </h2>
        <div className="row g-0 text-center main-Browse7-page ">
          {data3.map((e) => (
            <Link
              to={`/BrowserSidePage/${e.id}`}
              key={e + Math.random()}
              className="col-lg-4  col-md-6 "
            >
              <div className="mx-2 p-5  in-box my-3 text-center">
                <img className="img-fluid mt-1 " src={`${e.image}`} />
                <div className=" Browse7-page-name mb-1 my-3">{e.name}</div>

                <div className=" Browse7-page-person mb-3 fw-bold fs-5 pt-4">
                  {e.person}
                </div>
                <div className=" Browse7-page-type mb-3">{e.type}</div>
                <div className=" Browse7-page-icon ">{e.icon}</div>
              </div>
            </Link>
          ))}
        </div>
        <Member1 />
      </div>
    </div>
  );
}
