import React, { Component } from "react";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import img23 from "../images/img23.jpg";

import { Form, form, Input, Button, Drawer, DatePicker } from "antd";
import "./Whoarewe.css";

const Whoarewe = ({ setNewMessage, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "Chauffeur",
      attributeValue: [values],
    });

    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="side-whoarewe-page" id="nature">
        <section className="Whoarewe-page container">
          <h2 className="text-center">About JCOM</h2>
          <h6>Who can Join</h6>
          <h1>
            Let Us Connect 4 Business
            <br /> Growth
          </h1>
          <div className="d-lg-flex">
            <div className="col-12 col-lg-6 ">
              <br></br>
              <p>
                Junior Chamber International (JCI) is an international
                organization founded in 1915 at St Louis, USA, and having its
                International Head Quarters at Chesterfield, St. Louis, USA. It
                is an NGO, imparting training to youth, developing them and
                transforms them as active citizen, for sustainable positive
                change around the world. It works with many organizations
                including UN, for sustainable development globally. JCI is
                spread in above 100 countries. Each Country is known as a
                National Organization Member (NOM). In India, JCI was launched
                in 1949. JCI India is the NOM affiliated to JCI, which is the
                international body. JCI India is a registered society under the
                Societies Registration Act XXI of 1860 as amended by the
                Societies Registration Act (Punjab Amendment Act 1957) as
                extended to the State of Delhi.
              </p>
            </div>

            <img
              className=" col-12 col-lg-6 m-1 img-fluid m-md-4"
              data-aos="fade-up"
              data-aos-delay="200"
              src={img23}
            />
          </div>
        </section>
      </main>
    </div>
  );
};
export default Whoarewe;
