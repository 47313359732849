import React from "react";
import { Button } from "antd";
// import video from "../videos/video.mp4";
import "./BrowseHero1.css";
import CountUp from "react-countup";
import { AiOutlineMinus } from "react-icons/ai";
const BrowseHero1 = () => (
  <div>
    <main className="main-BrowseHero1-page" id="nature">
      <section
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className="BrowseHero1-page container"
      >
        <div>
          <div className="BrowseHero1-page-content text-left">
            <p
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              className="BrowseHero1-page-para1"
            >
              BROWSE TABLES{" "}
            </p>
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              DO MORE OF WHAT WORKS & LESS OF WHAT DOESN'T
            </h1>
            <br></br>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default BrowseHero1;
