import "./Testimonials.css";
import { Rate } from "antd";
import { Link } from "react-router-dom";
import img26 from "../images/img26.png";
import img27 from "../images/img27.png";
import img28 from "../images/img28.jpg";
import img29 from "../images/img29.jpg";
import img30 from "../images/img30.png";
import img31 from "../images/img31.png";
import { SiFacebook } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
export default function Testimonials() {
  const data = [
    {
      image: img26,
      person: "JFS. S. P. Rajalingam",
      type: "Zone President ",
      place: "Zone XVIII, JCI India.",
    },
    {
      image: img27,
      person: "JFM. V. Brabu",
      type: "Zone Chairman",
      place: "Zone XVIII, JCI India.",
    },
    {
      image: img28,
      person: "JCI Senator C. S. Aswin Kumar ",
      type: "JCOM Secretary",
      place: "Zone XVIII, JCI India.",
    },
    {
      image: img29,
      person: "Jc HGF. M. Arun Kumar",
      type: "Head Coach",
      place: "Zone XVIII, JCI India.",
    },
    {
      image: img30,
      person: "JFD P.Saravana Kumar ",
      type: "ZD. Business ",
      place: "Zone XVIII, JCI India.",
    },
    {
      image: img31,
      person: "JCI Sajeev Sadanandan ",
      type: "Digital Media Coordinator ",
      place: "Zone XVIII, JCI India.",
    },
  ];
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Testimonials-page-main-section"
    >
      <div className=" main-Testimonials-page container">
        <div className="Testimonials-page-para2 text-center">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            Zone Governing Board Members
          </h1>
        </div>
        <div className="row g-0 text-center main-Testimonials-page ">
          {data.map((e) => (
            <div to="/" key={e + Math.random()} className="col-lg-4  col-md-6 ">
              <div className="mx-2 p-5  in-box my-3 text-center">
                <img className="col-12 img-fluid mt-1 " src={`${e.image}`} />
                <div className=" Testimonials-page-name mb-1 my-3">
                  {e.name}
                </div>

                <div className=" Testimonials-page-person mb-3 fw-bold fs-5 pt-4">
                  {e.person}
                </div>
                <div className=" Testimonials-page-type mb-3">{e.type}</div>
                <div className=" Testimonials-page-icon fw-bold mb-3">
                  {e.place}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
