import "./BrowseTables.css";
import { Rate, Button } from "antd";
import { Link } from "react-router-dom";

import { SiFacebook } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
export default function BrowseTables() {
  const data = [
    {
      place: "JCOM MARTHANDAM 1.0",
      apply: (
        <span className="BrowseTables-btn ">
          <Link to="/Browse4" spy={true} offset={0}>
            <Button className="" type="primary ">
              View Table
            </Button>
          </Link>
        </span>
      ),
    },
    {
      place: "JCOM MADURAI 1.0",
      apply: (
        <span className="BrowseTables-btn ">
          <Link to="/Browse5" spy={true} offset={0}>
            <Button className="" type="primary ">
              View Table
            </Button>
          </Link>
        </span>
      ),
    },
    {
      place: "JCOM MADURAI 2.0",
      apply: (
        <span className="BrowseTables-btn ">
          <Link to="/Browse6" spy={true} offset={0}>
            <Button className="" type="primary ">
              View Table
            </Button>
          </Link>
        </span>
      ),
    },
    {
      place: "JCOM THIRUNELVELI 1.0",
      apply: (
        <span className="BrowseTables-btn ">
          <Link to="/Browse7" spy={true} offset={0}>
            <Button className="" type="primary ">
              View Table
            </Button>
          </Link>
        </span>
      ),
    },
    {
      place: "JCOM TUTICORIN 1.0",
      apply: (
        <span className="BrowseTables-btn ">
          <Link to="/Browse8" spy={true} offset={0}>
            <Button className="" type="primary ">
              View Table
            </Button>
          </Link>
        </span>
      ),
    },
    {
      place: "JCOM SIVAKASI 1.0",
      apply: (
        <span className="BrowseTables-btn ">
          <Link to="/Browse9" spy={true} offset={0}>
            <Button className="" type="primary ">
              View Table
            </Button>
          </Link>
        </span>
      ),
    },
    {
      place: "JCOM VIRUTHUNAGAR 1.0",
      apply: (
        <span className="BrowseTables-btn ">
          <Link to="/Browse10" spy={true} offset={0}>
            <Button className="" type="primary ">
              View Table
            </Button>
          </Link>
        </span>
      ),
    },
  ];
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="BrowseTables-page-main-section"
    >
      <div className=" main-BrowseTables-page container">
        <div className="BrowseTables-page-para2 text-center mt-5">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine ">
            Zones
          </h1>
        </div>
        <div className="row g-0 text-center main-BrowseTables-page ">
          {data.map((e) => (
            <div key={e + Math.random()} className="col-lg-4  col-md-6 ">
              <div className="mx-4 p-1  in-BrowseTables-box my-3 text-center">
                <div className=" BrowseTables-page-place mb-3 fw-bold fs-5 pt-4">
                  {e.place}
                </div>
                <div className=" BrowseTables-btn  mb-3  fs-5 pt-4">
                  <Link to="/"> {e.apply}</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
