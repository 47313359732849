import React, { Component } from "react";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import img24 from "../images/img24.jpg";

import { Form, form, Input, Button, Drawer, DatePicker } from "antd";
import "./Mission.css";

const Mission = ({ setNewMessage, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "Chauffeur",
      attributeValue: [values],
    });

    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="side-Mission-page" id="nature">
        <section className="Mission-page container">
          <div className="d-lg-flex">
            <img
              className=" col-12 col-lg-6 m-1 img-fluid m-md-4"
              data-aos="fade-up"
              data-aos-delay="200"
              src={img24}
            />
            <div className="col-12 col-lg-6 ">
              <br></br>
              <h1>Vision</h1>
              <ul>
                <li className="mission-para1">
                  To be the Leading Chamber of Commerce of Young JCI
                  Entrepreneurs
                </li>
              </ul>
              <h1>Mission</h1>
              <ul>
                <li className="mission-para2">
                  To provide Business Development opportunities that empower
                  young Entrepreneurs towards Business Growth.
                </li>
                <li className="mission-para2">
                  Developing Business Leaders for a Changing World.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default Mission;
