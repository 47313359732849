import "./App.css";
import Wholeapp from "./Wholeapp";

import AOS from "aos";
import "aos/dist/aos.css";

import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="">
      <Wholeapp />
    </div>
  );
}

export default App;
