import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
// import video from "../videos/video.mp4";
import "./Chamber.css";
import CountUp from "react-countup";
import { AiOutlineMinus } from "react-icons/ai";
const Chamber = () => (
  <div>
    <main className="main-Chamber-page " id="nature">
      {/* <div className="hero-video-box order-md-1 order-0">
        <video className="" autoplay="autoplay" loop="true" muted>
          <source src={video} type="video/mp4"></source>
        </video>
      </div> */}
      <section className="Chamber-page container">
        <div>
          <div className="Chamber-page-content col-md-8  text-left">
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              Jaycees Chamber of Commerce
            </h1>
            <p
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              className="Chamber-page-para1"
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. */}
            </p>
          </div>

          <div className="text-left pt-3">
            <span className="Chamber-btn text-left mt-5">
              <Button type="primary" key="console">
                <Link to="/">CONTACT US</Link>
              </Button>
            </span>
          </div>

          {/* <div className="hero-page-para text-left">
            <AiOutlineMinus />
            <span>
              <p
                className="hero-page-para2 mx-3"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
              >
                Lorem ipsum dolor sit amet, consectetur <br />
                adipiscing elit, sed do.
              </p>
            </span>
            <br></br>
          </div> */}
        </div>
      </section>
    </main>
  </div>
);

export default Chamber;
