import React, { Component, useState } from "react";
import "../App.css";

// import Browse1 from "./Browse1/Browse1";
// import Browse2 from "./Browse2/Browse2";
// import Browse3 from "./Browse3/Browse3";

import BrowseHero1 from "./BrowseHero1/BrowseHero1";
import BrowseTables from "./BrowseTables/BrowseTables";
// import Browse4 from "./Browse4/Browse4";
export default function Browse({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="Browse">
      <BrowseHero1 />
      <BrowseTables />
      {/* <Browse4 /> */}
      {/* <Browse1 />
      <Browse2 />
      <Browse3 /> */}
    </div>
  );
}
