import "./Member1.css";
import { Rate } from "antd";
import { Link } from "react-router-dom";

import img32 from "../images/img32.jpg";
import { SiFacebook } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
export default function Member1() {
  const data = [
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
    {
      image: img32,
      person: "Person Name",
      type: "Position",
    },
  ];
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Member1-page-main-section"
    >
      <div className=" main-Member1-page container">
        <div className="Member1-page-para2 text-center">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            Members
          </h1>
        </div>
        <div className="row g-0 text-center main-Member1-page ">
          {data.map((e) => (
            <Link
              to="/"
              key={e + Math.random()}
              className="col-lg-3  col-md-6 "
            >
              <div className="mx-2 p-5  in-box my-3 text-center">
                <img className="col-12 img-fluid mt-1 " src={`${e.image}`} />
                <div className=" Member1-page-name mb-1 my-3">{e.name}</div>

                <div className=" Member1-page-person mb-3 fw-bold fs-5 pt-4">
                  {e.person}
                </div>
                <div className=" Member1-page-type mb-3">{e.type}</div>
                <div className=" Member1-page-icon fw-bold mb-3">{e.place}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
