import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { Modal } from "antd";
import Home from "./Components/Home";
import AboutUs from "./Components/Aboutus";
import Browse from "./Components/Browse";
import Contact from "./Components/Contact";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Browse4 from "./Components/Browse4/Browse4";
import Browse5 from "./Components/Browse5/Browse5";
import Browse6 from "./Components/Browse6/Browse6";
import Browse7 from "./Components/Browse7/Browse7";
import Browse8 from "./Components/Browse8/Browse8";
import Browse9 from "./Components/Browse9/Browse9";
import Browse10 from "./Components/Browse10/Browse10";
import Member1 from "./Components/Member1/Member1";
import BrowseSidepage1 from "./Components/BrowseSidePage/BrowseSidepage1";
import FooterLast from "./Components/FooterLast/FooterLast";

import { useParams } from "react-router-dom";
const Wholeapp = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [newValues, setNewValues] = useState({});

  let { id } = useParams();
  return (
    <Router>
      <Navbar />

      <Routes>
        <Route path="/">
          <Route
            exact={true}
            index
            element={
              <Home
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/AboutUs">
          <Route
            exact={true}
            index
            element={
              <AboutUs
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Contact">
          <Route
            exact={true}
            index
            element={
              <Contact
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Browse">
          <Route
            exact={true}
            index
            element={
              <Browse
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Browse4">
          <Route exact={true} index element={<Browse4 />} />
        </Route>
        <Route path="/Browse5">
          <Route exact={true} index element={<Browse5 />} />
        </Route>
        <Route path="/Browse6">
          <Route exact={true} index element={<Browse6 />} />
        </Route>
        <Route path="/Browse7">
          <Route exact={true} index element={<Browse7 />} />
        </Route>
        <Route path="/Browse8">
          <Route exact={true} index element={<Browse8 />} />
        </Route>
        <Route path="/Browse9">
          <Route exact={true} index element={<Browse9 />} />
        </Route>
        <Route path="/Browse10">
          <Route exact={true} index element={<Browse10 />} />
        </Route>
        <Route path="/Member1">
          <Route exact={true} index element={<Member1 />} />
        </Route>

        <Route path="BrowserSidePage">
          <Route path=":id" index element={<BrowseSidepage1 />} />
          {/* <Route exact={true} index element={<BrowseSidepage1 />} /> */}
        </Route>
      </Routes>
      <Footer />
      <FooterLast />
    </Router>
  );
};
export default Wholeapp;
