import React, { Component } from "react";

import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

import "./FooterLast.css";
import { Link } from "react-router-dom";
const FooterLast = () => (
  <div>
    <main className="main-FooterLast-page">
      <section className="FooterLast-page ">
        <div className=" container ">
          <div className="FooterLast-copyright-section d-md-flex justify-content-between">
            <div>
              <div className="copyright-content ">
                <p>
                  {" "}
                  Copyright © 2022 JCOM. All Rights Reserverd. Powered by Obo
                  Technology Private Limited.{" "}
                </p>

                <a href="http://www.obotechnology.com" target="_blank"></a>
              </div>
            </div>
            <div>
              {/* <div className="copyright-content ">
                Privacy Policy */}
              {/* <a href="http://www.obotechnology.com" target="_blank"> */}
              {/* Terms & Conditions */}
              {/* </a> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default FooterLast;
