import React, { Component, useState } from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import img23 from "../images/img23.jpg";

import { GoMail } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { CgWebsite } from "react-icons/cg";
import { ImLocation2 } from "react-icons/im";
import { TbBrandProducthunt } from "react-icons/tb";
import { MdMedicalServices } from "react-icons/md";
import "./BrowseSidepage1.css";
import {
  data,
  data1,
  data2,
  data3,
  data4,
  data5,
  data6,
} from "../../Data/AllData";

import { useParams } from "react-router-dom";
import { useEffect } from "react";

const BrowseSidepage1 = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [position, setPosition] = useState("");
  const [showData, setShowData] = useState({});
  const [allData, SetAllData] = useState([
    ...data,
    ...data1,
    ...data2,
    ...data3,
    ...data4,
    ...data5,
    ...data6,
  ]);
  const { id } = useParams();
  const handleOk = () => {
    setModalVisible(false);
  };
  //   const data = [
  //     {
  //       head: "Tuesday 13 May, 2022",
  //       person: "BY JOHN HUNAU DEO",
  //       name: "CHURCH WAS DOING WHAT HE OFTEN DID WHEN DROPPED AN ORACLE",
  //       content:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.",
  //       image: img23,
  //     },
  //   ];

  useEffect(() => {
    const prodData = () => {
      let resData = allData.filter((x) => parseInt(id) === x.id);
      setShowData(resData[0]);
    };

    prodData();
  }, [showData]);

  console.log(showData);

  console.log(id);
  return (
    <div>
      <main className="side-BrowseSidepage1-page" id="nature">
        <div className="BrowseSidepage1-page mt-4">
          {" "}
          <p
            data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            className=" text-center"
          >
            PROFILE
          </p>
        </div>
        <div className="BrowseSidepage1-page"></div>
        <section
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="BrowseSidepage1-page container"
        >
          <div className=""></div>
          <div className="row g-3  content-pack">
            <div className="col-lg-12  ">
              <div className="mx-sm-5 p-sm-5 in-BrowseSidepage1-box ">
                <div className="d-lg-flex justify-content-center  mt-3 ">
                  <div className="mx-0 col-lg-6 text-center">
                    <img
                      className="img-fluid mt-1 "
                      src={`${showData && showData.image}`}
                    />
                    <div className="">
                      <div className=" mt-4 BrowseSidepage1-person text-center px-5">
                        {showData && showData.person}
                      </div>
                      <div className=" mt-0 BrowseSidepage1-type p-0 text-center">
                        {showData && showData.type}
                      </div>
                    </div>
                  </div>
                  <div className=" BrowseSidepage1-icon col-lg-6 ">
                    <div className=" mt-2 mx-3 BrowseSidepage1-email  d-flex">
                      <span>
                        {" "}
                        <GoMail />
                      </span>
                      <span className="mx-2">{showData && showData.email}</span>
                    </div>
                    <div className=" mt-2 mx-3 BrowseSidepage1-phonenumber  d-flex">
                      <span>
                        {" "}
                        <FiPhoneCall />
                      </span>
                      <span className="mx-2">
                        {showData && showData.phonenumber}
                      </span>
                    </div>
                    <div className=" mt-2 mx-3 BrowseSidepage1-companyname  d-flex">
                      <span>
                        {" "}
                        <HiOutlineBuildingOffice2 />
                      </span>
                      <span className="mx-2">
                        {showData && showData.companyname}
                      </span>
                    </div>
                    <div className=" mt-2  mx-3 BrowseSidepage1-website  d-flex">
                      <span>
                        {" "}
                        <CgWebsite />
                      </span>
                      <span className="mx-2">
                        {showData && showData.website}
                      </span>
                    </div>
                    <div className=" mt-2 mx-3 BrowseSidepage1-location  d-flex">
                      <span>
                        {" "}
                        <ImLocation2 />
                      </span>
                      <span className="mx-2">
                        {" "}
                        {showData && showData.location}
                      </span>
                    </div>
                    <div className=" mt-2 mx-3 BrowseSidepage1-products  d-flex">
                      <span>
                        {" "}
                        <TbBrandProducthunt />
                      </span>
                      <span className="mx-2">
                        {" "}
                        {showData && showData.products}
                      </span>
                    </div>
                    <div className=" mt-2 mx-3 BrowseSidepage1-services  d-flex">
                      <span>
                        {" "}
                        <MdMedicalServices />
                      </span>
                      <span className="mx-2">
                        {showData && showData.services}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default BrowseSidepage1;
