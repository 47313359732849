import React, { useState } from "react";
import { Modal, Input, InputNumber, Form, Button, message } from "antd";
import "./ContactUs1.css";

import { SiFacebook } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
export default function ContactUs1({ category, setCategory }) {
  const [page, setPage] = useState("");
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "inbox",
      attributeValue: [values],
    });
    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.statusCode === 200) {
          message.success("Request Submitted Successfully");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="contact-main-page   " id="contactus">
        <section
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="contact-page container"
        >
          <div className="text-start">
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              CONTACT FORM:
            </h1>
          </div>
          <Form
            form={form}
            onFinish={handleFinish}
            className="contact-form"
            layout="vertical"
            // data-aos="fade-up"
            // data-aos-easing="ease-in-sine"
            // data-aos-delay="500"
          >
            <div className="d-lg-flex justify-content-between">
              <div className="col-12 col-lg-6 m-1 ">
                <div
                  data-aos="fade-up "
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="300"
                  className="contact-page-box  "
                >
                  <Form.Item
                    className="my-3"
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First name!",
                      },
                    ]}
                  >
                    <Input placeholder="Your Full Name*" className="phone" />
                  </Form.Item>
                  <Form.Item
                    className="my-3"
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Mail Id!",
                      },
                    ]}
                  >
                    <Input placeholder="Your Mail*" className="" />
                  </Form.Item>
                  <Form.Item
                    className="where my-3"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Your Query",
                      },
                    ]}
                  >
                    <Input placeholder="Query Related" className="" />
                  </Form.Item>
                  <Form.Item
                    className="where my-3"
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Message For Us!",
                      },
                    ]}
                  >
                    <TextArea rows={3} placeholder=" Message" />
                  </Form.Item>
                  <Form.Item className=" contact-btn ">
                    <Button htmlType="submit">SEND MESSAGE</Button>
                  </Form.Item>
                </div>
              </div>

              <div
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-delay="300"
                className="col-12 col-md-6 contact-page-big-textarea "
              >
                <div>
                  <div className="contact-page-visit ">
                    <br></br>
                    <div className="contact-page-line"> </div>
                    <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
                      Contact Details
                    </h1>
                    <p
                      data-aos="fade-right"
                      data-aos-easing="ease-in-sine"
                      className="contact-page-visit-address2 "
                    >
                      <span className="">
                        +91 9843766786
                        <br />
                        jcomzone18@gmail.com
                      </span>
                    </p>
                    <br></br>
                  </div>

                  <div
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="300"
                    className="col my-lg-0 my-3"
                  >
                    <div className="contact-social-icons">
                      <div
                        data-aos="fade-up"
                        data-aos-easing="ease-in-sine"
                        data-aos-delay="100"
                        className="contact-para-icon "
                      >
                        <h1
                          data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                          className="contact-page-para3"
                        >
                          Find us here
                        </h1>
                        <span className="">
                          <SiFacebook />
                        </span>
                        <span className="">
                          {" "}
                          <AiFillTwitterCircle />
                        </span>
                        <span>
                          {" "}
                          <IoLogoLinkedin />{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </Form>
        </section>
      </main>
    </div>
  );
}
