import React, { Component } from "react";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import img7 from "../images/img7.png";

import { Form, form, Input, Button, Drawer, DatePicker } from "antd";
import "./Whoarewe1.css";

const Whoarewe1 = ({ setNewMessage, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "Chauffeur",
      attributeValue: [values],
    });

    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="side-Whoarewe1-page" id="nature">
        <section className="Whoarewe1-page container">
          <h6>Who can Join</h6>
          <h1>
            Let Us Connect 4 Business
            <br /> Growth
          </h1>
          <div className="d-lg-flex">
            <div className="col-12 col-lg-6 ">
              <br></br>
              <p>
                JCOM board will consists of National President , Immediate Past
                National President, National Director Business, General Legal
                Council, National Treasurer, Secretary General, one EVP and
                other members appointed by the National President for guiding
                JCOM. The term of office for the JCOM officers will be one year
                from January 1st to December 31st. The Chairperson of the JCOM
                board can be appointed by the National President. The Chairman
                can invite special invitees to JCOM Board meeting in
                consultation with the National President. The JCOM National
                Board will meet once in 30 days either virtually or physically
                to look into the progress and activities of JCOM. The annual
                general body meeting of the JCOM will be held along with the
                National convention. All the JCOM Zone Chairman will be the
                special invitee for the JCOM meetings
              </p>
            </div>

            <img
              className=" col-12 col-lg-6 m-1 img-fluid m-md-4"
              data-aos="fade-up"
              data-aos-delay="200"
              src={img7}
            />
          </div>
        </section>
      </main>
    </div>
  );
};
export default Whoarewe1;
