import React, { Component, useState } from "react";
import "../App.css";
import AboutHero1 from "./AboutHero1/AboutHero1";
import Mission from "./Mission/Mission";

import Whoarewe from "./Whoarewe/Whoarewe";
export default function Aboutus({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="Aboutus">
      <AboutHero1 />
      <Whoarewe />
      <Mission />
    </div>
  );
}
