import React, { Component, useState } from "react";
import "../App.css";
import { Modal } from "antd";
import Hero from "./Hero/Hero";
import Career from "./Career/Career";
import Chamber from "./Chamber/Chamber";
import Whoarewe1 from "./Whoarewe1/Whoarewe1";
import Testimonials from "./Testimonials/Testimonials";
// import ContactUs from "./ContactUs/ContactUs";
export default function Home({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="home">
      <Hero />
      <Career />
      <Chamber />
      <Whoarewe1 />
      <Testimonials />
      {/* <ContactUs /> */}
    </div>
  );
}
