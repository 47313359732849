import "./Navbar.css";
import React, { useState } from "react";
import img1 from "../images/img1.png";
import img from "../images/img.png";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "antd";
function Navbar() {
  const [icon, setIcon] = useState(false);
  const [page, setPage] = useState("Contact");
  const handleClick = () => {
    setIcon(!icon);
  };
  const closeSideDrawer = () => {
    setIcon(false);
  };

  const Menus = [
    { li: "/Business", title: "Home" },
    { li: "/AboutUs", title: "About JCOM" },
    { li: "/Browse", title: "Browse Tables" },
    // { li: "/Blog", title: "Blog" },
    { li: "/Contact ", title: "Contact Us" },
  ];

  const ToggleNavBar = () => {
    let element = document.getElementsByClassName("navbar-toggler")[0];
    if (element.getAttribute("aria-expanded") == "true") {
      element.click();
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <div className="navbar-logo">
            <img className="img-fluid" src={img1} />
          </div>

          <button
            className="navbar-toggler bg-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={`navbar-nav mx-auto `}>
              {Menus.map((e, i) => (
                <li>
                  <NavLink
                    end
                    to={e.li}
                    className={`nav-links ${({ isActive }) =>
                      isActive ? "active" : ""}`}
                    onClick={() => ToggleNavBar()}
                  >
                    {e.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="">
            <img className="img-fluid" src={img} />
          </div>
          {/* <div className="nav-btn">
            <span className=" ">
              <Button type="primary" key="console">
                <Link to="/Contact">Contact Us</Link>
              </Button>
            </span>
          </div> */}
        </div>
      </nav>
    </>
  );
}
export default Navbar;
